import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    ranchoCordovaImage: file(relativePath: { eq: "rancho-cordova.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoWithRekeySetImage: file(relativePath: { eq: "leo-with-rekey-set.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function RanchoCordovaPage({ data }) {
  return (
    <MainLayout
      title="24 Hour Local Locksmith in Rancho Cordova, CA | 916-995-0135"
      description="Our full-service locksmith company in Rancho Cordova, CA offers car, home & commercial building lockouts, rekeying, ignition key replacement & more."
      offset
    >
      <ContactHeading
        background={data.ranchoCordovaImage.childImageSharp.fluid}
      >
        <h1 className="text-center">Rancho Cordova Locksmith</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Locksmith in Rancho Cordova
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2 className="text-center">Leading Locksmith in Rancho Cordova, CA</h2>
        <p className="indent">
          Having a sense of security is crucial in today's world. Swift
          locksmith has been serving the Rancho Cordova community and the nearby
          areas for over a decade and we have years of experience with various
          locksmith situations. Whether you need security locksmith solutions or
          an emergency response, we will make sure that you will always feel
          safe. The safety and satisfaction of our customers is our first
          priority and we always make sure that you our satisfied with the job
          before leaving.
        </p>
        <p className="indent mb-0">
          Give us a call today for lockouts, repairs, keyless entry methods, and
          to improve the security for your home, business or vehicle. All of our
          technicians are licensed, friendly and professional and we work around
          the clock to provide 24/7 customer service to all of our customers. We
          strive to provide you with the best, trustworthy, and affordable
          customer service.
        </p>
      </Container>
      <section>
        <div className="background-content bg-dark text-white">
          <Container>
            <h2 className="text-center">What We Offer</h2>
            <Row>
              <Col xs={12} md={6}>
                <h3 className="text-primary">Residential Locksmith</h3>
                <p>
                  From basic to high security needs, Swift Locksmith in Rancho
                  Cordova, CA provides an assortment of residential locksmith
                  services and products. Regardless if you need your locks
                  repaired, a duplicate key, or need to rekey your new home, we
                  have you covered.
                </p>
              </Col>
              <Col xs={12} md={6}>
                <h3 className="text-primary">Automotive Locksmith</h3>
                <p>
                  Our automotive technicians have been in the auto locksmith
                  industry for a long time and have the expertise and skills to
                  help with any auto locksmith situation that you may have. We
                  are always prompt to arrive at your location, efficient and
                  get the job done right the first time around so you can save
                  time and money and get back to your regular routine.
                </p>
              </Col>
              <Col xs={12} md={6}>
                <h3 className="text-primary">Commercial Locksmith</h3>
                <p className="mb-md-0">
                  We have been helping Rancho Cordova businesses feel safe and
                  stay secure for over ten years and our skilled technicians are
                  pros at providing various security solutions for all types of
                  businesses. We make sure to listen to each clients needs and
                  specialize our services to meet their security goals.
                </p>
              </Col>
              <Col xs={12} md={6}>
                <h3 className="text-primary">Emergency Locksmith</h3>
                <p className="mb-0">
                  Swift Locksmith Rancho Cordova is always ready to help you
                  with all of your locksmith needs any day, any time. We are
                  open 24 hours a day, 7 days a week, so you can always rely on
                  us to be of service to you. We respond to all our calls
                  urgently to ensure that we can quickly come to you and assist
                  you in a timely manner.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="d-none d-md-block">
        <Row>
          <Col>
            <Img
              fluid={data.leoWithRekeySetImage.childImageSharp.fluid}
              alt="leo-with-rekey-set"
              style={{ height: "100%", maxHeight: 350 }}
            />
          </Col>
          <Col>
            <Img
              fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
              alt="locksmith-van-in-city"
              style={{ height: "100%", maxHeight: 350 }}
            />
          </Col>
          <Col>
            <Img
              fluid={data.leoInCarImage.childImageSharp.fluid}
              alt="leo-in-car"
              style={{ height: "100%", maxHeight: 350 }}
            />
          </Col>
        </Row>
      </section>
      <Container as="section" className="mb-5">
        <h2>Why Choose Swift Locksmith in Rancho Cordova, CA</h2>
        <p className="indent">
          Swift Locksmith in Rancho Cordova, CA provides reassurance for people
          looking to secure their property and offers emergency response for
          individuals who need to get back in their vehicles, offices, and
          houses. Our team of friendly, professional locksmiths have the
          training and expertise to unlock, repair and install any type of lock.
        </p>
        <p className="indent mb-0">
          We understand that whenever you lose your keys or are locked out of
          your home or vehicle, it can be very stressful and frustrating so we
          try to respond to every call as urgently as possible. Swift locksmith
          technicians always arrive with the proper materials and tools to help
          you resolve your locksmith issue quickly and efficiently. As a local,
          licensed and insured locksmith company, we are devoted to helping and
          providing locksmith solutions for the people of Rancho Cordova, CA and
          the surrounding areas.
        </p>
      </Container>
    </MainLayout>
  )
}

export default RanchoCordovaPage
